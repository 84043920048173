import { Directive, EventEmitter, ElementRef,
          HostListener, Input, Output } from '@angular/core';
import { FileItem } from '../models/file-item';
import { FilmItem } from '../models/film-item';

@Directive({
  selector: '[appNgDropFiles]'
})
export class NgDropFilesDirective {

  @Input() files: any[] = [];
  @Input() fileExists: boolean;
  @Output() mouseOver: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  @HostListener('dragover', ['$event'])
  public onDragEnter( event: any ) {
    this.mouseOver.emit(true);
    this._preventStop(event);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave( event: any ) {
    this.mouseOver.emit(false);
  }

  @HostListener('drop', ['$event'])
  public onDrop( event: any ) {
    const transferencia = this._getTransferencia(event);

    if ( !transferencia ) {
      return;
    }
    
    this._extractFiles( transferencia.files );
    this._preventStop(event);
    this.mouseOver.emit(false);
  }

  private _getTransferencia( event:any ) {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
  }

  private _extractFiles( filesList: FileList ) {

    for( const prop in Object.getOwnPropertyNames( filesList )) {
      const tempFile = filesList[prop];
      if( this._fileOkToDrop( tempFile ) ) {
        const newFile = ( window.location.hash.includes('photos') ) ? new FileItem( tempFile ) :new FilmItem( tempFile );
        this.files.push( newFile );
      }
    }

    console.log(this.files)
  }

  // Validations
  private _fileOkToDrop( file: File ): boolean {
    if ( !this._dropFile( file.name ) && this._isImage( file.type ) && this._fileSize( file.size ) ) {
      return true;
    } else {
      return false;
    }
  }

  private _preventStop( event ) {
    event.preventDefault();
    event.stopPropagation();
  }

  private _dropFile( fileName: string ): boolean {
    for( const archivo of this.files ) {
      if ( archivo.name == fileName ) {
        console.log('The file ' + fileName + ' is already loaded' );
        this.fileExists = true;
        // console.log(this.fileExists);
        return true;
      }
    }
    return false;
  }

  private _isImage( fileType: string ): boolean {
    return ( fileType === '' || fileType === undefined ) ? false : fileType.startsWith('image');
  }

  private _fileSize( fileSize: number ) {
    const mbSize = fileSize / 1024;
    if ( mbSize > 1024 ) {
      return false
    }

    return true;
  }
}
