import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = 'https://identitytoolkit.googleapis.com/v1/accounts:';
  private apiKey = 'AIzaSyB89LLEMlWGAHbAaMk1jfLARiAs2Qavf8k';
  public isLogged = false;
  public inLogin = false;

  userToken: string;

  // Create new users
  // https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]

  // Login
  // https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=[API_KEY]

  constructor( private http: HttpClient,
                        private router: Router ) {
    this.readToken();
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  login( user: object ) {
    const authData = {
      ...user,
      returnSecureToken: true,
    };

    return this.http.post(
      `${ this.url }signInWithPassword?key=${this.apiKey}`,
      authData
    ).pipe(map( res => {
      this.saveToken( res['idToken'] );
      return res;
    }));
  }

  signup( user: object) {
    const authData = {
      ...user,
      returnSecureToken: true,
    };

    return this.http.post(
      `${ this.url }signUp?key=${this.apiKey}`,
      authData
    ).pipe(map( res => {
      this.saveToken( res['idToken'] );
      return res;
    }));
  }

  private saveToken( idToken: string ) {
    this.userToken = idToken;
    localStorage.setItem('token', idToken);

    let today = new Date();
    today.setSeconds(3600);

    localStorage.setItem('expires', today.getTime().toString());
    this.isLogged = true;
  }

  private readToken() {
    return this.userToken = localStorage.getItem('token') ? localStorage.getItem('token') : '';
  }

  isAuth(): Observable<boolean> {
    if ( this.userToken.length < 2 ) {
      if (window.location.pathname === '/admin') this.router.navigateByUrl('/login');
      this.isLogged = false;
      return observableOf(false)
      // return false;
    }

    const expires = Number(localStorage.getItem('expires'));
    const tokenExpires = new Date();
    tokenExpires.setTime(expires);

    if ( tokenExpires > new Date() ) {
      // return true;
      this.isLogged = true;
      return observableOf(true)
    } else {
      // return false;
      //  this.router.navigateByUrl('/login');
      this.isLogged = false;
      return observableOf(false)
    }

    this.isLogged = (this.userToken.length > 2);
    return observableOf(this.userToken.length > 2);
  }
}
