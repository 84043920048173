import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs/operators'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  dropdownLabel:string = '';
  isLogged:boolean = false;
  inLogin = false;
  title:string = '';

  constructor( public translate: TranslateService,
              public auth: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,  
              private titleService: Title) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    router.events.subscribe(
      obs => {
        const url  = obs['url'];

        if (url) {
          this.inLogin = ( url === '/login' );
        }
      }
    )

    this.auth.isAuth().subscribe(res => this.isLogged = res)
  }

  switchLang(lang) {
    debugger;
    document.querySelector('span.active').classList.remove('active');
    document.querySelector(`[data-lang="${lang}"`).classList.add('active');
    this.translate.use(lang);
  }

  ngOnInit() {
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {  
        const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {
          // const title = ( data.title !== 'Photography' )
          //   ? data.title
          //   : `${this.activatedRoute.firstChild.params['value'].category}`;
          //let title;
          if ( data.title !== 'Photography' ) {
            this.title = data.title;
            this.dropdownLabel = 'photography';
          } else {
            this.title = `${this.activatedRoute.firstChild.params['value'].category}`;
            this.dropdownLabel = this.title;
          }
      
          this.translate.get(this.title).subscribe((text:string) => this.titleService.setTitle(text));
        });
      });  
  }  
  
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }  
}
