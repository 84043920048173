import { Component, OnInit } from '@angular/core';
// import * as firebase from 'firebase';
// import { CollectionsService } from 'src/app/services/collections.service';
import {  AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
// import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { films } from "../../helpers/uploadFields";


@Component({
  selector: 'app-projects',
  templateUrl: './covers.component.html',
  styleUrls: ['./covers.component.scss']
})
export class CoversComponent implements OnInit {

  private itemsCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;
  isLogged: boolean = false;
  fields:any[] = [];

  constructor(private afs: AngularFirestore,
              private auth: AuthService) {
    this.itemsCollection = afs.collection<any>('films');
    this.items = this.itemsCollection.valueChanges();
    this.isLogged = auth.isLogged;
    this.fields = films;
    // this.itemsCollection = afs.collection<Item>('img');
    // this.items = this.itemsCollection.valueChanges();
  }

  ngOnInit() {
   
  }

}
