export class FileItem {
    public file: File;
    public fileName: string;
    public fileUrl: string;
    public loading: boolean;
    public progreso: number;

    constructor( file: File ) {
        this.file = file;
        this.fileName = file.name;

        this.loading = false;
        this.progreso = 0;
    }
}