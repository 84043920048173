import { Component, Input, OnInit } from '@angular/core';
import { CargaImagenesService } from 'src/app/services/carga-imagenes.service';
// import { FilmItem } from 'src/app/models/film-item';
import { ActivatedRoute } from '@angular/router';
import { FileSizePipe } from 'src/app/pipes/file-size.pipe';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Input() fields: any[] = [];

  category:string = '';
  overDrop = false;
  files: any[] = [];
  fileExists: boolean = false;


  constructor( public _cargaImagenes: CargaImagenesService,
              private activatedRoute: ActivatedRoute,
              public fileSize: FileSizePipe ) {
    this.activatedRoute.params.subscribe( params => {
      this.category = ( params.category) ? params.category : 'films';
      this.limpiarImagenes();
    });
  }

  cargarImagenes() {
    this._cargaImagenes.cargarImagenesFirebase( this.category, this.files );
  }

  limpiarImagenes() {
    this.files = [];
  }

  

  ngOnInit() {
  }

}
