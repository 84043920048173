import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { CoversComponent } from './components/covers/covers.component';
import { HomeComponent } from './components/home/home.component';
// import { AuthGuard } from './services/auth.guard';
import { PhotosComponent } from './components/photos/photos.component';
import { AboutComponent } from './components/about/about.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { title: 'Home' }  },
  { path: 'about', component: AboutComponent, data: { title: 'About' }  },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'photos/:category', component: PhotosComponent, data: { title: 'Photography' } },
  { path: 'films', component: CoversComponent, data: { title: 'Films' } }, // , canActivate: [ AuthGuard ] },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
