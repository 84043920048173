import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';


/* Custom components */
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { CardsComponent } from './components/shared/cards/cards.component';
import { LoginComponent } from './components/login/login.component';
import { CoversComponent } from './components/covers/covers.component';
import { environment } from '../environments/environment';
import { HomeComponent } from './components/home/home.component';
// import { CarouselComponent } from './components/home/carousel.component';
import { SocialComponent } from './components/shared/social/social.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import * as firebase from 'firebase';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireDatabase } from 'angularfire2/database';
import { UploadComponent } from './components/shared/upload/upload.component';
import { NgDropFilesDirective } from './directives/ng-drop-files.directive';
import { PhotosComponent } from './components/photos/photos.component';
import { NoResultsComponent } from './components/shared/no-results/no-results.component';
import { LightboxModule } from 'ngx-lightbox';
import { FileSizePipe } from './pipes/file-size.pipe';
import { AboutComponent } from './components/about/about.component';


firebase.initializeApp(environment.firebase);

export const firebaseconfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: ''
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CardsComponent,
    LoginComponent,
    CoversComponent,
    HomeComponent,
    // CarouselComponent,
    SocialComponent,
    UploadComponent,
    NgDropFilesDirective,
    PhotosComponent,
    NoResultsComponent,
    FileSizePipe,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    },
    }),
    LightboxModule,
  ],
  providers: [ FileSizePipe, Title ],
  bootstrap: [AppComponent]
})

export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}