import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { Lightbox } from 'ngx-lightbox';
import { TranslateService } from '@ngx-translate/core';

import { photos } from "../../helpers/uploadFields";
import { revealCards } from "../../helpers/reveals";
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  private _albums: any[] = [];

  category:string = '';
  isLogged:boolean = false;
  private itemsCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;
  fields: any[];

  constructor( private afs: AngularFirestore,
               private activatedRoute: ActivatedRoute,
               private auth: AuthService,
               private _lightbox: Lightbox,
               public translate: TranslateService,
               public storage: AngularFireStorage) {

    this.isLogged = auth.isLogged;

    this.activatedRoute.params.subscribe( params => {
      this.category = params.category;
      this.itemsCollection = afs.collection<any>(`${ this.category }`, ref => ref.orderBy('name'));
      // debugger;
      this.items = this.itemsCollection.valueChanges();
      this._albums.push(this.items);

      this.items.subscribe( photos => {
        this._albums = [...photos];
        revealCards();
        // this.items = null;
      });
    });

    this.fields = photos;
  }

  ngOnInit() {
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index, { showZoom: true, wrapAround: true, showImageNumberLabel: true, disableScrolling: true, centerVertically: true, fitImageInViewPort: true });
    // console.log(this._albums)
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
