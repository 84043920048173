import { Injectable } from '@angular/core';

import {  AngularFirestore } from "@angular/fire/firestore";
import * as firebase from 'firebase';
// import { FileItem } from '../models/file-item';

@Injectable({
  providedIn: 'root'
})
export class CargaImagenesService {

  private CARPETA_IMAGENES;

  constructor( private db: AngularFirestore ) { }

  cargarImagenesFirebase( folder: string, imagenes: any[] ) {
    const storageRef = firebase.storage().ref();
    this.CARPETA_IMAGENES = folder;

    for ( const item of imagenes ) {
      item.loading = true;
      if( item.progreso >= 100 ) {
        continue;
      }
        const tmpName = ( this.CARPETA_IMAGENES === 'films' ) ? item.filmName : item.fileName;
        const uploadTask: firebase.storage.UploadTask = 
        storageRef.child(`${ this.CARPETA_IMAGENES }/${ tmpName }`)
          .put( item.file );

        uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED,
          ( snapshot: firebase.storage.UploadTaskSnapshot ) => {
            item.progreso = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
            // uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            //   console.log('File available at', downloadURL);
            //   item.url = downloadURL;
            // });
          },
          ( error ) => console.error('Error al subir', error),
          () => {
            var tmpURL = '';
            console.log('Imagen Cargada correctamente');
            console.log(item)
            item.url = uploadTask.snapshot.ref.getDownloadURL().then( downloadURL => {
              item.loading = false;
              if ( this.CARPETA_IMAGENES === 'films' ) {
                this.guardarFilm({
                  name: item.filmName.replace('.jpg',''),
                  year: item.year,
                  director: item.director,
                  url: downloadURL
                });
              } else {
                this.guardarFilm({
                  name: item.fileName.replace('.jpg',''),
                  url: downloadURL,
                  src: downloadURL
                });
              }
              
            } );
            
            
            // switch (folder) {
            //   case 'films':
            //     this.guardarFilm( {
            //       name: item.filmName,
            //       year: item.filmYear,
            //       director: item.filmDirector,
            //       url: item.url
            //     } )
            //     break;
            
            //   default:
            //     break;
            // }
            
          }
        )
    }
  }

  private guardarFilm( film: any ) {
    this.db.collection(`/${ this.CARPETA_IMAGENES}`)
      .add( film );
  }
}
