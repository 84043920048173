import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { animateElement } from "../../helpers/animateElement";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor( public translate: TranslateService ) {
  }

  switchLang(lang) {
    document.querySelector('span.active').classList.remove('active');
    document.querySelector(`[data-lang="${lang}"`).classList.add('active');
    this.translate.use(lang);
  }

  ngOnInit() {
    animateElement();
    handleScroll();
  }
}

function handleScroll() {
  const pageHeader = document.querySelector<HTMLElement>('.floating-header');
  const container = document.querySelector<HTMLElement>('.caption');
  const containerHeight = container.offsetHeight;
  window.onscroll = () => {
    if ( pageHeader.offsetTop >  ( containerHeight - 110) ) { // + pageHeader.offsetHeight
      pageHeader.style.background = 'transparent';
    } else {
      pageHeader.style.background = 'rgba(0,0,0,0.2)';
    }
  }
}