import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  social = [
    {
      name: 'Instagram',
      class: 'fab fa-instagram mr-3',
      url: 'https://www.instagram.com/sofiacarrasco.dp/',
    },
    {
      name: 'LinkedIn',
      class: 'fab fa-linkedin-in mr-3',
      url: 'https://www.linkedin.com/in/silvia-sof%C3%ADa-carrasco-santos-8414041a9/?originalSubdomain=mx',
     },
     {
      name: 'Email',
      class: 'far fa-envelope',
      url: 'mailto:sil_sofi@outlook.com',
     }
  ];

  constructor() {}

  ngOnInit() {
  }

}
