import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { revealCards } from "../../../helpers/reveals";

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  category:string = '';
  private itemsCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;

  @Input() photos: any[];

  constructor( private afs: AngularFirestore,
    private activatedRoute: ActivatedRoute) {

      this.activatedRoute.params.subscribe( params => {
      this.category = ( params.category) ? params.category : 'films';
      this.itemsCollection = afs.collection<any>(`${ this.category }`);
      this.items = this.itemsCollection.valueChanges();
      
      this.items.subscribe(() => revealCards());
      
      });
    }

  ngOnInit() {
    // revealCards();
  }

}
