import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {
    email: '',
    password: '',
  };
  rememberUser = false;

  constructor( private auth: AuthService,
               private router: Router ) {
    this.user = {
      email: '',
      password: '',
    };
  }

  ngOnInit() {
    if ( localStorage.getItem('email') ) {
      this.user.email = localStorage.getItem('email');
      this.rememberUser = true;
    }
  }

  login( form: NgForm ) {
    if ( form.invalid ) { return; }

    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Please wait..'
    });

    Swal.showLoading();
    this.auth.login( this.user ).
      subscribe(() => {
        Swal.close();
        this.router.navigateByUrl('admin');
        if ( this.rememberUser ) {
          localStorage.setItem('email', this.user.email);
        }
      },
      (err: any) => {
        console.log(err.error.error.message);
        Swal.fire({
          type: 'error',
          text: err.error.error.message.replace('_', ' ')
        });

      });
  }

}
