import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if ( typeof value === 'number' ) {
      return `${(value / 1024).toFixed(2)} KB`;
    }
    return value;
  }

}
