import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import {  AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  // styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() dropdownLabel:string;
  @Input() title:string;
  private itemsCollection: AngularFirestoreCollection<any>;
  photoSubitems: Observable<any[]>;

  inLogin = false;
  isLogged = false;


  categories = [
    { path: '/home', name: 'Home' },
    { path: '/photography', name: 'photography', hasSubItems: true },
    { path: '/films', name: 'Films' },
    { path: '/about', name: 'About' },
  ];

  constructor( public translate: TranslateService,
               public auth: AuthService,
               private afs: AngularFirestore,
               private titleService: Title,
               ) {
    this.auth.isAuth().subscribe(res => this.isLogged = res);
    this.itemsCollection = afs.collection<any>('categories', ref => ref.orderBy('id'));
    this.photoSubitems = this.itemsCollection.valueChanges();
  }

  ngOnInit() {
    
  }

  switchLang(lang) {
    document.querySelector('span.active').classList.remove('active');
    document.querySelector(`[data-lang="${lang}"`).classList.add('active');
    this.translate.use(lang);
    const translated = this.translate.get(this.title).subscribe((text:string) => this.titleService.setTitle(text));
  }

  handleLogout() {
    this.auth.logout();
  }
}
