export class FilmItem {
    public file: File;
    public filmName: string;
    public year: number;
    public director: string;
    public fileUrl: string;
    public loading: boolean;
    public progreso: number;

    constructor( file: File ) {
        this.file = file;
        this.filmName = file.name;
        this.loading = false;
        this.progreso = 0;
    }
}